import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import AttendATraining from "../images/attend-a-training.svg"
import ObserveACase from "../images/observe-a-case.svg"
import ShareYourObservations from "../images/share-your-observations.svg"
import Support from "../images/support.svg"
import Team from "../images/team.svg"

import CourtsChart from "../components/courts-chart"

const IndexPage = () => (
  <Layout>
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <h3 className="title is-family-secondary">Houston Eviction Solidarity Network</h3>
          <h3 className="subtitle">Facing evictions together.</h3>
          <h3 className="subtitle">What is the Eviction Solidarity Network? </h3>
          <div className="columns">
            <div className="column is-8">
              <div className="content">
                <p>
                  The Houston Eviction Solidarity Network is a project of the <a href="https://texashousers.org/" target="_blank" rel="noopener noreferrer">Texas Housers</a> Houston office.  It’s inspired by the Eviction Solidarity Network created by our partners <a href="http://bastaaustin.org/" target="_blank" rel="noopener noreferrer">BASTA! in Austin</a>.  Eviction Solidarity Network aims to do a few things: 
                </p>
                <ul>
                  <li>Reduce the shame and stigma associated with evictions by being a visible and supportive presence in virtual and in-person courtrooms.</li>
                  <li>Conduct a lay courtwatch to ensure that judges are treating those facing eviction fairly and checking all their boxes.</li>
                  <li>Humanize a complex, systemic problem and uplift tenant stories (anonymously or with permission).</li>
                </ul>
              </div>
            </div>
            <div className="column is-4-mobile is-offset-4-mobile">
              <figure className="image">
                <Support width="100%" height="100%"/>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section has-background-light">
      <div className="container">
        <div className="columns">
          <div className="column is-4-mobile is-offset-4-mobile">
            <figure className="image">
              <Team width="100%" height="100%"/>
            </figure>
          </div>
          <div className="column is-8">
            <h3 className="title is-family-secondary">Who can become a court observer?</h3>
            <div className="content">
              <p>
                Join the Houston ESN by attending a training!
              </p>
              <p>
                After attending a training session anyone can join the Houston ESN.
                People can join as representatives of a group or individuals.
                As long as hearings are streamed, participants in the Houston ESN don’t even have to live in Harris County.
              </p>
              <p>
                Members of the Houston ESN must agree to certain basic norms covered in the training.
                You’ll need a decent internet connection and device that connects to the internet to join.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container is-fluid">
        <h3 className="title is-family-secondary">How does it work?</h3>
        <div className="columns is-multiline how-it-works-cols">
          <div className="column is-4-desktop is-6-tablet is-12-mobile">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-64x64 is-hidden-mobile is-hidden-desktop">
                      <AttendATraining width="100%" height="100%"/>
                    </figure>
                    <figure className="image is-128x128 is-hidden-tablet-only">
                      <AttendATraining width="100%" height="100%"/>
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-6">Attend a training.</p>
                    <Link to="/training" className="button is-warning is-rounded is-small title" activeClassName="is-focused">
                      Sign up here.
                    </Link>
                  </div>
                </div>
                <div className="content">
                  <div className="is-size-7">
                    <p>First, let us know you’re interested and we’ll get you set up with a training session over video chat.</p>
                    <p>As a member of the ESN you’ll receive occasional calls to action when there are opportunities for public comment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4-desktop is-6-tablet is-12-mobile">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-64x64 is-hidden-mobile is-hidden-desktop">
                      <ObserveACase width="100%" height="100%"/>
                    </figure>
                    <figure className="image is-128x128 is-hidden-tablet-only">
                      <ObserveACase width="100%" height="100%"/>
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-6">Observe a case.</p>
                    <Link to="/observe" className="button is-success is-rounded is-small title" activeClassName="is-focused">
                      Sign up here.
                    </Link>
                  </div>
                </div>
                <div className="content">
                  <div className="is-size-7">
                    <p>After your training, decide how you want to participate in the ESN.</p>
                    <ul>
                      <li>You can act as a presence in the courtroom (we prefer people don’t risk exposure by attending in-person hearings, but everyone is free to make their own choices).</li>
                      <li>You can pay a little bit more attention and enter notes in a handy form we have that will help us spot any issues.</li>
                      <li>And, if you don’t have time to court watch you can always share stats that we share on social media to keep the conversation going around evictions.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4-desktop is-6-tablet is-12-mobile">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-64x64 is-hidden-mobile is-hidden-desktop">
                      <ShareYourObservations width="100%" height="100%"/>
                    </figure>
                    <figure className="image is-128x128 is-hidden-tablet-only">
                      <ShareYourObservations width="100%" height="100%"/>
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-6">Share your observations.</p>
                  </div>
                </div>
                <div className="content">
                  <p className="is-size-7">You'll receive a link in your email where you can submit your observations.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CourtsChart/>
  </Layout>
)

export default IndexPage
