/**
 * CourtsChart component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { ResponsiveCalendar } from "@nivo/calendar"
import { format } from "date-fns"

import { path, pathOr, map, pipe, applySpec, groupBy, values, sortBy, reject, isNil, concat, sum } from "ramda"

const makeCourtDayLink = (dayData, courtData) => {
  const courtInfo = courtData[0]
  const courtInLink = courtInfo.court.replace(/\D/g,'')
  const day = format(dayData.date, 'MM/dd/yyyy')

  return `https://jpwebsite.harriscountytx.gov/WebDockets/GetDocketDetail?date=${day}&court=${courtInLink}&media=screen`
}

const CourtsChart = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allAirtable(filter: {}, sort: {order: ASC, fields: data___Start_Time}) {
        nodes {
          data {
            Court_Name
            Number_of_Cases
            Judge
            Name
            Docket_Date
            Link_to_Docket
          }
          id
        }
      }
    }
  `)

  const chartData = pipe(
    path(['allAirtable', 'nodes']),
    reject(
      pipe(
        path(['data', 'Court_Name', 0]),
        isNil,
      ),
    ),
    groupBy((item) => (
      concat(path(['data', 'Court_Name', 0])(item), path(['data', 'Docket_Date'])(item)))
    ),
    map(applySpec({
      court: path([0, 'data', 'Court_Name', 0]),
      day: path([0, 'data', 'Docket_Date']),
      judge: path([0, 'data', 'Judge', 0]),
      value: pipe(map(pathOr(0, ['data', 'Number_of_Cases'])), sum),
    })),
    values,
    groupBy(path(['court'])),
    values,
    sortBy(path([0, 'court'])),
  )(data)

  return (
    <section className="section" id="courts-summary">
      <div className="container is-fluid">
        <h4 className="title is-family-secondary is-4">Dockets by day for each court</h4>
        <p className="subtitle">Learn about the courts that hear eviction cases in Harris County.</p>
        <div className="content">
          <p>
            There are 16 Justice of the Peace Courts that hear eviction cases across Harris County.  Every precinct has a different volume of renters and judges have been interpreting orders and guidance differently throughout the pandemic.
          </p>
          <p>
            Dockets are the time slots during which judges hear cases. The is no maximum or minimum amount of cases per docket.  
          </p>
          <p>Below is a chart that shows how frequently different courts hear eviction cases.</p>

          <ul>
            <li>Each box represents a day in the year</li>
            <li>The darkness of the color of each box represents how many cases are or were scheduled for the day</li>
            <li>Each row represents Sunday through Saturday of a week</li>
            <li>Each column represents a week in the year</li>
          </ul>

          <p>
            The differences in number of cases and frequency of dockets throughout the courts is just the tip of the iceberg
            on the role of the courts in the outcomes of eviction hearings on our fellow Neighbors.
          </p>
          <p className="call-to-action">
            <Link to="/training" className="button is-warning is-rounded is-small" activeClassName="is-focused">
              Train to be an Observer
            </Link> to help us uncover more ways to make the system more equitable.
          </p>
        </div>
        <div className="courts-chart-section-wrapper">
          {
            map((courtData) => (
              <div className="columns courts-chart-section" key={courtData[0].court}>
                <div className="column is-2">
                  <div className="courts-row">
                    <div className="courts-row-label">
                      <p className="title is-6">{courtData[0].court}</p>
                      <p className="subtitle">{courtData[0].judge}</p>
                    </div>
                  </div>
                </div>
                <div className="column is-10">
                  <div className="courts-chart-wrapper">
                    <ResponsiveCalendar
                      data={courtData}
                      from="2020-01-02"
                      to="2021-12-31"
                      align="left"
                      theme={{
                        fontSize: 14,
                        textColor: "#3d3b8e",
                      }}
                      emptyColor="#EFEFEF"
                      colors={[ '#fee8c8', '#fdd49e', '#fdbb84', '#fc8d59', '#ef6548', '#d7301f', '#990000' ]}
                      margin={{ top: 40, right: 10, bottom: 40, left: 20 }}
                      direction="horizontal"
                      yearSpacing={40}
                      monthBorderColor="#3d3b8e"
                      monthLegendPosition="before"
                      dayBorderWidth={2}
                      dayBorderColor="#ffffff"
                      tooltip={(data) => (<span className="tooltip box">{format(data.date, 'iii, LLL d')}: <strong>{data.value || 0} cases</strong></span>)}
                      onClick={(data) => {
                        window.open(makeCourtDayLink(data, courtData), '_blank')
                      }}
                      legends={[
                        {
                          anchor: 'bottom-right',
                          direction: 'row',
                          translateY: 36,
                          itemCount: 4,
                          itemWidth: 42,
                          itemHeight: 36,
                          itemsSpacing: 14,
                          itemDirection: 'right-to-left'
                        }
                      ]}
                    />
                  </div>
                </div>
              </div>
            ))(chartData)
          }
        </div>
      </div>
    </section>
  )
}

export default CourtsChart
